import JsEval from "../../modules/utils/utils-js/theme/js/JsEval";
import Form from "../../modules/core/forms/theme/js/Form";
import SVGInject from "../../modules/media/svg/theme/js/SVGInject";

class Site {
    public static discover($parent?: JQuery): void {
    }

    private static initHtml() {
        this.discover();
    }

    /**
     * @override
     */
    public static init(): void {
        (<any> window).Site = Site;

        Form.init();
        SVGInject.init();

        $(document).ready(() => {
            Site.initHtml();
        });

        JsEval.eval();
    }
}

Site.init();
