export default class JsEval {
    public static eval(): void {
        (<any> window)._js = (h: () => void) => {
            h();
        };

        let js = (<any> window)._jslist;

        $.each(js, (k, v) => {
            v();
        });
    }

    public static init(): void {
        (<any> window).JsEval = JsEval;
    }
}

